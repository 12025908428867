input { font-family: 'Inter-Regular'; }
textarea { font-family: 'Inter-Regular'; }

#root {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.dialog_root {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: auto;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
  background-color: white;
  border-radius: 10px;
  margin: auto;
}

.app {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.app_phone_root {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
}

.app_phone_ctn {
  width: 100%;
  max-width: 400px;
}

.view_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view_center_absolute {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.ntv_header_a {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
  align-items: center;
}

.ntv_bga_color_a {
  background-color: rgba(238, 38, 37, 0.25);
}

.ntv_bga_color_b {
  background-color: rgba(0, 0, 0, 0.5);
}

.blk_bga_color_a {
  background-color: rgba(234, 234, 234, 0.2);
}

.blk_bga_color_b {
  background-color: rgba(234, 234, 234, 0.7);
}

.ntv_bg_color_a {
  background-color: #ee2625;
}

.gry_bg_color_a {
  background-color: #888888;
}

.gren_bg_color_a {
  background-color: #00cb00;
}

.ntv_bga_color_a { background-color: rgba(238, 38, 37, 0.25); }
.ntv_bga_color_b { background-color: rgba(0, 0, 0, 0.5); }
.ntv_bga_color_c { background-color: rgba(238, 38, 37, 0.75); }
.ntv_accent_a { accent-color: #ee2625; }

.crs_pointer {
  cursor: pointer;
}

.crs_default {
  cursor: default;
}

.fnt_wht_a {
  color: white;
}

.fnt_ntv_a {
  color: #ee2625;
}

.fnt_blk_a {
  color: #888888;
}

.fnt_blk_reject {
  color: #cc0000;
}

.fnt_blk_appr {
  color: #039f00;
}

.txt_bold {
  font-weight: bold;
}

.txt_12 {
  font-size: 12pt;
}

.txt_14 {
  font-size: 14pt;
}

.txt_16 {
  font-size: 16pt;
}

.txt_20 {
  font-size: 20pt;
}

.txt_30 {
  font-size: 30pt;
}

.txt_10 { font-size: 10pt; }
.txt_12 { font-size: 12pt; }
.txt_14 { font-size: 14pt; }
.txt_16 { font-size: 16pt; }
.txt_20 { font-size: 20pt; }
.txt_30 { font-size: 30pt; }
.txt_36 { font-size: 36pt; }

.button_a {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.dropdown_dsp_none {
  display: none;
}

.dropdown_dsp_show {
  display: flex;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

.single_line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* CUSTOM CSS DASHBOARD */

.dash_action_ctn {
  float: left;
  width: calc(100% / 3);
  height: 150px;
}

.dash_action {
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

/* CUSTOM CSS CALENDAR SALESMAN */

.cal_date_ctn {
  float: left;
  width: calc(100% / 5);
  height: 100px;
}

/* CUSTOM CSS UPLOAD PHOTO */

.upd_photo_ctn {
  float: left;
  width: calc(100% / 2 - 5px);
  height: 250px;
  position: relative;
}

.upd_photo {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.class_product {
  display: flex;
  flex-direction: row;
  float: left;
  width: calc(100% / 2 - 5px);
  height: 50px;
}

/* CUSTOM LIBRARY */

.react-daterange-picker__wrapper {
  border: 0 !important;
}

.react-daterange-picker { width: 100%; }

.react-daterange-picker__range-divider {
  font-weight: bold;
  padding-right: 5px;
}

.react-daterange-picker__inputGroup__input { cursor: pointer; }

/* CUSTOM CSS SALESMAN HOMEPAGE */

.img_sales_main_a {
  width: 100%;
  height: auto;
}

/* ANDROID VIEW ON 420px */

@media only screen and (max-width : 420px) {
  .img_sales_main_a {
    height: 90%;
    width: auto;
  }
}

.card {
  display: inline-block;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .15);
  margin: 20px;
  border: rgba(0, 0, 0, .15);
  position: relative;
  transition: all .2s ease-in-out;
  border-radius: 5px;
  min-height: 300px;
  max-height: 300px;
}

.card-2 {
  display: inline-block;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
  margin: 20px;
  transition: all .2s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card:hover {
  /*box-shadow: 0 5px 22px 0 rgba(0,0,0,.25);*/
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.image {
  width: 140px;
  height: 150px;
  object-fit: cover;
}


.text {
  background: #FFF;
  padding-bottom: 10px;
}

.text p {
  margin-bottom: 0px;
  padding: 0px 0px 0 0px;

}

.column {
  width: 50%;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  display: inline-block;
  box-sizing: border-box;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}


.modal {
  position: absolute;
  max-width: 30rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  background: rgba(39, 48, 39, 0.3);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Green background with 30% opacity */
  ;
}

.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
  opacity: 0.5;
}

.modalImage {
  width: 300px;
  height: 700px;
  object-fit: cover;
}


.center {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
