body {
  margin: 0;
  font-family: 'Inter-Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./fonts/Inter-Regular.ttf) format('truetype');
}
